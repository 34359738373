import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Crisp for Startup`}</p>
    <p>{`Go from 0 to 1 by collecting valuable feedback from your customers`}</p>
    <p>{`Cross-channel customer support experiences using modern messaging`}</p>
    <p>{`Marketing`}</p>
    <p>{`Retarget customers by sending target emails and in-app messages`}</p>
    <p>{`Sales`}</p>
    <p>{`Sell more by automating your sales pipeline using bots`}</p>
    <p>{`Ready to improve your customer engagement?Try Crisp for freeRequest a demo`}</p>
    <p>{`Livechat`}</p>
    <p>{`Engage you visitor using Livechat`}</p>
    <p>{`Integration`}</p>
    <p>{`Connect crisp to your favorite app`}</p>
    <p>{`Campaigns`}</p>
    <p>{`Knowledge`}</p>
    <p>{`Status Page`}</p>
    <p>{`Increase Customer satisfaction using Live Chat software`}</p>
    <p>{`Much more than just a Live Chat software
Beyond Live Chat, Crisp simplifies your customer support by unifying all the channels of your customer relationship. Your customers benefit from an incredible shared inbox experience. Respond to your customers from one software and reduce your costs thanks to our Live Chat software!`}</p>
    <p>{`Get 2x More Leads Using Automation
Statistics show that website visitors who receive assistance through a live chat are more likely to make a purchase. With live chat triggers, you can send automated messages that create a proactive customer service which converts visitors into opportunities.`}</p>
    <p>{`Have a Richer Conversation
Business messaging doesn't have to be robotic.
With Crisp, you can create a personalized experience for your customers.`}</p>
    <p>{`usiness messaging doesn't have to be robotic. With Crisp, you can create a personalized experience for your customers.`}</p>
    <p>{`Integrated knowledge management systemsUsers can access help articles and resolve issues directly from our Live Chat
Chatbot scenariosBuild advanced chatbot scenerios to automate your customer onboarding.
GIFs + Video PreviewCreate personal and modern conversational experiences using GIFs and videos.
File sharingShare files, images, and documents directly from the chat widget.
SocialConnect the Crisp Live Chat to social networks like Messenger and Twitter
CrispyBirdA fun game that helps keep your visitors engaged when you are offline.
Customize your Live Chat
From colors, to languages, to positions; you can customize Crisp to fit your brand`}</p>
    <p>{`Gather all communications in one shared inbox
Let your customers contact you through their favorite day-to-day messaging apps, while you maintain a single dashboard for all of your replies. Crisp is connected to many third-party messaging channels like Facebook Messenger, Email, Twitter, Telegram, Twilio (SMS) and Line. It simplifies your customer support.`}</p>
    <p>{`LiveTranslate
Become a wordwide brand using LiveTranslate. This feature enables you to translate real-time conversations. Reply to customers using your native language and it will automatically translate to your customer's language so they can have a better customer support experience with you.`}</p>
    <p>{`The first video chat for customers
Scheduling calls with customers can be complicated sometimes. Customers are usually required to set up third-party software in order to communicate with you, but with our Live Chat software, your customers can enjoy video and audio calls out of the box. No plug-in or software to install, just a joyful and human experience.`}</p>
    <p>{`MagicBrowse
When your users would rather have you do it for them, Crisp lets you co-browse with your customers without any other plugin than our live chat software`}</p>
    <p>{`Ready to improve your customer engagement?Try Crisp for freeRequest a demo
Contact us
About us
Intercom alternative
REST API`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      